import { AvatarBadge } from '@/components/pages/ethical-oath/badge/page.content'
import useOathStatsQuery from '@/hooks/queries/useOathStatsQuery'
import { formatNumbers } from '@/lib/utils'

export const ThirdStep = () => {
  const { data: oathCounter } = useOathStatsQuery({})
  const userOathCounter = oathCounter?.users_accepted
    ? formatNumbers(oathCounter?.users_accepted)
    : 0

  const oathCounterLabel = `You and ${userOathCounter} people have signed the Oath.`
  return (
    <div className="flex flex-col items-center justify-center gap-10 bg-white p-6 sm:p-10 lg:p-20">
      <div className="flex flex-col items-center gap-2 text-center">
        <span>{oathCounterLabel}</span>
        <div className="flex flex-col items-center gap-2.5">
          <h2 className="text-h2 font-bold leading-h2">Hurray!🎉 </h2>
          <h4 className="text-h4 font-bold leading-h4">
            Thank you for signing the oath
          </h4>
        </div>
      </div>
      <div className="flex flex-col items-center gap-10 sm:flex-row">
        <AvatarBadge />
        <div className="flex flex-col gap-4">
          <span>
            Your conscious decision to choose ethical practices is vital for the
            future of technology.
          </span>
          <span>
            As a token of our appreciation, we are delighted to award you with
            this badge. Display it with pride and inspire others 💪
          </span>
        </div>
      </div>
    </div>
  )
}
