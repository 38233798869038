import { MdipClient } from '@/lib/mdip/client'
import { useMutation } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'

export default function useSignOath() {
  const { data: session } = useSession()
  const sessionToken = session?.token
  return useMutation({
    mutationFn: async (sign: boolean) => {
      return MdipClient({
        url: '/api/v1/auth/users/current',
        method: 'put',
        payload: {
          is_oath_accepted: sign,
        },
        sessionToken,
      })
    },
  })
}
