import { Avatar, AvatarGroup } from '@mui/material'
import JoinImage from '@public/assets/join.png'
import Image from 'next/image'
import { ArrowOutward, Button } from '@/lib/mui'

interface Props {
  handler: () => void
  oathCounter: number | undefined
}

export const FirstStep = ({ handler, oathCounter }: Props) => (
  <div className="flex flex-col items-center gap-6 px-3 py-12">
    <Image
      src={JoinImage}
      alt="Person holding a flower in front of a robotic arm"
      width={400}
      height={200}
      className="w-full rounded-lg object-cover"
    />
    <div className="flex flex-col items-center gap-3 px-20 sm:px-6">
      <h1 className="text-2xl text-center font-bold">
        Join the movement toward a more ethical and sustainable future.
      </h1>
      <p className="text-center text-gray-600">
        Sign the Project Hafnia Ethical Oath and show your commitment to
        creating a better world through technology. Let&apos;s create a brighter
        future together!
      </p>
      <Button color="primary" endIcon={<ArrowOutward />} onClick={handler}>
        Read the Ethical Oath
      </Button>
      <AvatarGroup max={4}>
        {[...Array(6)].map((_, i) => (
          <Avatar
            alt=""
            src={`https://loremflickr.com/320/240/girl?lock=${i}`}
            key={i}
          />
        ))}
      </AvatarGroup>
      {oathCounter && (
        <p className="text-sm text-gray-500">
          {oathCounter} Developers have signed the Oath
        </p>
      )}
    </div>
  </div>
)
