import { TinaMarkdown, TinaMarkdownContent } from 'tinacms/dist/rich-text'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import classNames from 'classnames'

export const MarkdownLocal = ({
  content,
  replaceData,
  className,
  'data-tina-field': tinaField,
}: {
  content: string | TinaMarkdownContent
  replaceData?: any
  className?: string
  'data-tina-field'?: string
}) => {
  if (typeof content === 'string' || content instanceof String) {
    if (replaceData) {
      for (const key in replaceData) {
        if (replaceData[key]) {
          content = content.replaceAll(`{{${key}}}`, replaceData[key])
        } else {
          content = content.replaceAll(`{{${key}}}`, '_+_')
          content = content.replaceAll(`**_+_**`, '')
        }
      }
    }

    return (
      <Markdown
        remarkPlugins={[remarkGfm]}
        className={classNames('markdown', className)}
        data-tina-field={tinaField}
      >
        {content as string}
      </Markdown>
    )
  } else {
    return (
      <span
        className={classNames('markdown', className)}
        data-tina-field={tinaField}
      >
        <TinaMarkdown content={content as TinaMarkdownContent} />
      </span>
    )
  }
}
