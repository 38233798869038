import React from 'react'
import { DialogContent, Dialog } from '@mui/material'
import { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { FirstStep, SecondStep, ThirdStep } from './Steps'
import { useGlobalDialogs } from '@/components/GlobalDialogs/GlobalDialogs.context'
import { useRouter } from 'next/navigation'
import useOathStatsQuery from '@/hooks/queries/useOathStatsQuery'

export const OathDialog = () => {
  const { state, actions } = useGlobalDialogs()
  const { data: oathCounter } = useOathStatsQuery({})
  const [signStep, setSignStep] = useState<1 | 2 | 3>(1)
  const router = useRouter()
  const changeStep = (step: 1 | 2 | 3) => {
    setSignStep(step)
  }

  const handleClose = () => {
    actions.setEthicalOath({ open: false })
    // Reset the step after the dialog is closed
    setTimeout(() => setSignStep(1), 300)
    if (signStep !== 3) {
      router.back()
    }
  }

  return (
    <Dialog
      scroll="body"
      open={state.ethicalOath.open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: '6px',
          minWidth: 'fit-content',
        },
      }}
    >
      <DialogContent style={{ padding: '0px 0px 0px 0px' }}>
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-5">
          <div className="relative max-h-screen w-full max-w-[50rem] overflow-auto rounded-lg bg-white shadow-xl">
            <CloseIcon
              onClick={handleClose}
              className="absolute right-2 top-2 cursor-pointer text-gray-600 hover:text-neutral-400"
            />

            {signStep === 1 && (
              <FirstStep
                handler={() => changeStep(2)}
                oathCounter={oathCounter?.users_accepted}
              />
            )}
            {signStep === 2 && (
              <SecondStep
                handler={() => changeStep(3)}
                closeHandler={handleClose}
              />
            )}
            {signStep === 3 && <ThirdStep />}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
